







import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component({
  components: {
    BottomContactSection: () =>
      import(
        /* webpackChunkName: 'bottom-contact-section-view-bottom-contact-section' */ "@/components/BottomContactSection.vue"
      )
  }
})
export default class BottomContactSectionView extends Vue {}
